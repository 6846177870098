import React from 'react'

import {Row, Col} from 'antd';

import './footer.css';

import FacebookIcon from './facebook.png';

const Footer = (props) => {
    return <Row type="flex" justify="space-between" className='site-footer-container'>
                    <Col md={12}>
                        <h3>Get in Contact</h3>
                        <ul className="list-unstyled">
                            <li>Call Chris on <a href="tel:07827292090">07827 292090</a></li>
                            <li>Call Andrew on <a href="tel:07889755878">07889 755878</a></li>
                            <li>Email us: <a href="mailto:ippon.ryu.jujitsu@gmail.com">ippon.ryu.jujitsu@gmail.com</a></li>
                        </ul>
                    </Col>
                    <Col md={12}>
                        <h3>Find us on the Internet</h3>
                        <a href="http://www.facebook.com/ipponryu"><img height="30px" src={FacebookIcon} alt='Facebook Icon'/>&nbsp;www.facebook.com/ipponryu</a>
                    </Col>
        </Row>
};

export default Footer;