import React from 'react';
import './App.css';

import {Provider} from 'mobx-react';

import SessionStore from './stores/SessionStore';
import Navigation from './components/navigation/Navigation';

import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import { Layout} from 'antd';

// Pages

import SiteFooter from './components/footer';
import {Home, 
    OurClasses, 
    Locations, 
    Instructors, 
    AdultSyllabus, 
    FamilySyllabus,
    AdultCosts, 
    FamilyCosts,
    Covid
} from './components/pages';

const { Content, Footer } = Layout;

const sessionStore = new SessionStore();

const App = () => (

        <Provider sessionStore={sessionStore}>
            <Router>
                <Layout className='layout'>
                    <Navigation/>
                    <Content style={{ minHeight:'500px', padding: '0 50px 25px', background:'white' }}>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/classes">
                            <OurClasses />
                        </Route>
                        <Route path="/covid">
                            <Covid />
                        </Route>
                        <Route path="/locations">
                            <Locations />
                        </Route>
                        <Route path="/instructors">
                            <Instructors />
                        </Route>
                        <Route path="/instructors">
                            <Instructors />
                        </Route>
                        <Route path="/adults">
                            <AdultSyllabus />
                        </Route>
                        <Route path="/juniors">
                            <FamilySyllabus />
                        </Route>
                        <Route path="/adult_costs">
                            <AdultCosts />
                        </Route>
                        <Route path="/junior_costs">
                            <FamilyCosts />
                        </Route>
                    </Switch>
                    </Content>
                    <Footer>
                        <SiteFooter />
                    </Footer>
                </Layout>
            </Router>
        </Provider>
);

export default App;
