import './syllabus.css';

import React from 'react';
import {Row, Col} from 'antd';
import {Link} from 'react-router-dom';

import RedBelt from './belts/red-belt.png';
import YellowBelt from './belts/yellow-belt.png';
import OrangeBelt from './belts/orange-belt.png';
import GreenBelt from './belts/green-belt.png';
import BlueBelt from './belts/blue-belt.png';
import PurpleBelt from './belts/purple-belt.png';
import BrownBelt from './belts/brown-belt.png';
import BrownBlackBelt from './belts/brown-black-stripe.png';

const AdultSyllabus = () => {
return <div>
    <h1 className='ippon-ryu-font'>Adult Syllabus</h1>
    <hr/>
    To get and idea of training and start-up costs for Adults go <Link to="/adult_costs">here</Link>
    <h2 className='ippon-ryu-font'>Content</h2>
    <p>The Syllabus we teach has evolved and been proven over hundreds of years of martial combat to defend against
            a wide range of attacks. Ju Jitsu is a hands-on martial art where you will train with others in a safe-manner.
                We’re not all built the same, and different techniques suit different people’s
                body sizes and shapes. The broad range of techniques allows you to see what works for you. Whether you're
                an absolute beginner or someone with a Karate, Judo or Tae Kwon Do background looking to augment your training,
                Ju Jitsu has something to offer</p>

    <p>Ippon Ryu Ju Jitsu provides a comprehensive Adult Ju Jitsu syllabus that will take students of all entry
                skill levels and teach you skills in:</p>

    <ul>
        <li>Self-defence,</li>
        <li>Joint locking such as Wrist Locks, Arm-locks and Leg-locks,</li>
        <li>Blocking, countering and throwing,</li>
        <li>Ground grappling and restraints,</li>
        <li>Striking methods such as different types of punching methods and kicks,</li>
    </ul>

    <p>As you progress through the grades you'll also learn Traditional Weapons Kata forms including:</p>

    <ul>
        <li>6ft Bo</li>
        <li>Nunchaku</li>
        <li>Tonfa</li>
        <li>Hanbo</li>
        <li>Sai</li>
        <li>Kama</li>
        <li>Tachi/Bokken</li>
    </ul>
    <p>As your knowledge of the range of techniques grows, you’ll develop your fitness and self-confidence at the
                same time!</p>

    <h2 className='ippon-ryu-font'>Making Progress</h2>

    <p>Everybody has heard of the famous Black Belt, but in order to reach this level, we (like many other Martial
    Arts) stage your learning into a sequence of Kyu grades. Each grade is represented with a different colour
    belt starting with Red at 8th Kyu (Starter Grade) up to Brown with a black stripe at 1st Kyu. At the
    beginner grades you focus on landing safely from the techniques we teach, while learning the essentials of
    striking, Kuzushi (taking balance), throwing and basic joint locking. Once you have achieved 1st Kyu your
    training focuses on reviewing what you have learned, honing your technique with speed and fluidity in
        preparation for your Shodan (1st) Dan grading.</p>

    <Row type='flex' justify='center' className='belt-image'>
        <Col><img src={RedBelt} alt='red belt'/></Col>
        <Col><img src={YellowBelt} alt='yellow belt'/></Col>
        <Col><img src={OrangeBelt} alt='orange belt'/></Col>
        <Col><img src={GreenBelt} alt='green belt'/></Col>
        <Col><img src={BlueBelt} alt='blue belt'/></Col>
        <Col><img src={PurpleBelt} alt='purple belt'/></Col>
        <Col><img src={BrownBelt} alt='brown belt'/></Col>
        <Col><img src={BrownBlackBelt} alt='brown tag belt'/></Col>
    </Row>

    <p>There is a lot to learn, but there is no rush! For Adults We run a grading every 2 months where if you are
                ready for your next transition you are welcome to present yourself and take your test.</p>

    <h2 className='ippon-ryu-font'>How long does this all take?</h2>

    <p>The short answer is about 4 to 5 years of regular training to achieve Shodan. The more-involved answer is
    that the more you train, the faster you’ll progress through the grades! It does take time, dedication &
    training to achieve a Shodan grade which is why they are such a symbol of achievement. It’s not uncommon for
    this to take 4 to 5 years and sometimes longer. This may seem daunting but you can do it with dedication and
        perseverance. Importantly though, it is not a race and progress should be enjoyable, not a chore!</p>

    <p>The only firm time restriction between grades is between 1st Kyu to 1st Dan Black Belt which has a minimum 1
    year preparation time. That said, you must successfully pass a grading for the other Kyu Grades, and to be
        eligible to do this you must:</p>

    <ul>
        <li>Have trained regularly up to this grading</li>
        <li>Have valid up-to-date Licence & Insurance</li>
        <li>Be fit and well enough to grade</li>
    </ul>

    <h2 className='ippon-ryu-font'>Is there anything after Black Belt?</h2>

    <p>First Dan means just that - the First level of black belt. So you’re not done yet :). After Shodan, we have
    new content syllabus for each Dan grade up to Yondan (4th Dan). From Godan (5th Dan) onwards, the grades are
    honorary and awarded in discussion with the British Ju Jitsu Association. 2nd, 3rd and 4th Dan grades are by
        invitation only, with a set minimum number of years between grades. From here, the times between grades are notionally:</p>

    <ul>
        <li>Nidan (2nd Dan) - 2 years since Shodan Grading</li>
        <li>Sandan (3rd Dan) - 3 years since Nidan Grading</li>
        <li>Yondan (4th Dan) - 4 years since Sandan Grading</li>
        <li>And so on!</li>
    </ul>
</div>
};

export default AdultSyllabus;