import React from 'react';
import {Row, Col, Card} from 'antd';
import {Link} from 'react-router-dom';

import './locations.css';
import KnightwoodImage from './knightwood-leisure-centre-300x222.jpg';
import ToynbeeImage from './toynbee-school-300x222.jpg';
import RiverParkImage from './river-park.jpg';

const cardWidth = '360px';

const Locations =  () => {

    return <div>
        <h1 className='ippon-ryu-font'>Where we train</h1>
        <hr/>

        <Row>
            <h1>Where we train</h1>
        </Row>
        <Row>
            <Col><strong>Please note that access to these venues is slowly being reintroduced, please check the <Link to='/covid'>Covid</Link> page to see the latest</strong></Col>
        </Row>
        <Row>
            <Col>We train in 3 locations; 2 in the town of Chandlers Ford and 1 in the heart of Winchester</Col>
        </Row>
        <br/>
        <Row type='flex' justify='center' gutter={{xs: 0, md: 20}}>
            <Col>
                <Card className='locations-card' style={{width: cardWidth}}
                      title={<div className=''>
                                <h2>Knightwood Leisure Centre</h2>
                                <h4>Valley Park, Skys Wood Rd, Chandlers Ford, SO53 4SJ</h4>
                            </div>}
                      cover={<div className='locations-image-container'>
                                <img className='locations-image' src={KnightwoodImage} alt='Knightwood Leisure Centre'/>
                            </div>}>
                    <p>We train in the <strong>Badminton Hall</strong> of Knightwood
                        Leisure Centre on Tuesday evenings.</p>
                    <p>Juniors and Family training runs from 6:20pm until 7:30pm</p>
                    <p>Seniors (14 years old and over) train from 7:30pm until 9pm</p>
                </Card>
            </Col>
            <Col>
                <Card className='locations-card' style={{width: cardWidth}}
                      title={<div className=''>
                                <h2>Toynbee School</h2>
                                <h4>Bodycoats Rd, Chandlers Ford, SO53 2PL</h4>
                            </div>}
                      cover={<div className='locations-image-container'>
                                <img className='locations-image' src={ToynbeeImage} alt='Toynbee School'/>
                            </div>}>
                        <p>During term-time we train in the <strong>New Hall</strong> of Toynbee School on Thursday evenings.</p>
                        <p>Juniors and Family training runs from 6:20pm until 7:30pm</p>
                        <p>Seniors (14 years old and over) train from 7:30pm until 9pm</p>
                        <p>During the school Holidays we temporarily relocate to the main Sports Hall and train on Wednesdays. During these periods we run a mixed session from 7pm to 9pm</p>
                </Card>
            </Col>
            <Col>
                <Card className='locations-card' style={{width: cardWidth}}
                      title={<div className=''>
                                <h2>River Park Leisure Centre</h2>
                                <h4>Gordon Rd, Winchester SO23 7DD</h4>
                            </div>}
                      cover={<div className='locations-image-container'>
                                <img className='locations-image' src={RiverParkImage} alt='River Park Leisure Centre'/>
                            </div>}>
                        <p>On Sundays we train out of <strong>Studio 2</strong> in River Park Leisure Centre.</p>
                        <p>Combined Family and Seniors training runs from 6pm until 8pm all year round, except on Bank Holiday weekends</p>
                </Card>
            </Col>
        </Row>
    </div>
};


/*
description=
                    <img top className="location-image" src="static/img/knightwood-leisure-centre-300x222.jpg" alt="Card image cap" />

                <Card className="location-card">
                    <CardBody className="location-card-header-panel">
                        <CardTitle>
                        River Park Leisure Centre
                    </CardTitle>
                    <CardSubtitle>
                        Gordon Rd, Winchester SO23 7DD
                    </CardSubtitle>
                    </CardBody>
                    <CardImg top className="location-image" src="static/img/river-park.jpg" alt="Card image cap" />
                    <CardBody>
                        <p>On Sundays we train out of <strong>Studio 2</strong> in River Park Leisure Centre.</p>
                        <p>Combined Family and Seniors training runs from 6pm until 8pm all year round, except on Bank Holiday weekends</p>
                    </CardBody>
                </Card>
 */

export default Locations;