import React from 'react';
import {Row, Col, Card} from 'antd';
import {Link} from 'react-router-dom';

import ForChildrenImage from './kids-training.jpg';
import ForAdultsImage from './mid-kick.png';
import ClassesImage from './action-shot.jpg';
import TrainingImage from './clubmembers.jpg';


const {Meta} = Card; 

const Home = () => {

    const newsHeadStyle = {
        className: 'ippon-ryu-font'
    };

    return <div>
        <Row type='flex' justify='center' gutter={{xs: 0, md: 20}}>
            <Col>
                <Card title={<h1 className='ippon-ryu-font'>Welcome!</h1>}>
                    We're a Japanese Ju Jitsu Cub operating in the Chandlers Ford / Winchester Area! All ages and abilities welcome, we're a club that runs for the
                    benefit of its Members with experienced Instructors and fun family-oriented atmosphere that underpins everything we do!
                </Card>
                <br/>
                <Card title={<h1 className='ippon-ryu-font'>News & Updates</h1>}>
                    <Card type="inner" title={<h2 className='ippon-ryu-font'>Covid 19 - Back to training!</h2>} extra={<Link to="/covid">More</Link>}>
                          2021 updated - As of 20th May 2021 we're training on Thursday nights at our Toynbee Venue. We're following BJJAGB Covid Return-to-training guidance available <a href='http://www.bjjagb.com/news/bjjagb-return-to-play-action-plan-now-updated/'>Here</a>
                    </Card>
                </Card>
            </Col>
        </Row>
        <br/>
        <Row type='flex' justify='center' gutter={{xs: 0, md: 20}}>
        <Col>
            <Link to="/juniors"><Card style={{width:'200px'}} cover={<img src={ForChildrenImage} alt=''/>}>
                <Meta title='Ju Jitsu for Children'></Meta>
            </Card></Link>
        </Col>
        <Col>
            <Link to="/adults"><Card style={{width:'200px'}} cover={<img src={ForAdultsImage} alt=''/>}>
                <Meta title='Ju Jitsu for Adults'></Meta>
            </Card></Link>
        </Col>
        <Col>
            <Link to="/classes"><Card style={{width:'200px'}} cover={<img src={ClassesImage} alt=''/>}>
                <Meta title='About our Classes'></Meta>
            </Card></Link>
        </Col>
        <Col>
            <Link to="/locations"><Card style={{width:'200px'}} cover={<img src={TrainingImage} alt=''/>}>
                <Meta title='Where we train'></Meta>
            </Card></Link>
        </Col>
        </Row>

</div>;
};

export default Home;