import React from 'react';
import { Menu, Drawer, Layout, Button} from 'antd';
import { Link } from 'react-router-dom';

import { observer, inject } from 'mobx-react'

import MyImage from '../../img/irlogo.gif';
import './NavBar.css';
import windowSize from 'react-window-size';

@inject('sessionStore')
@observer
class Navigation extends React.Component {

    state = {};

    renderNavLinks(toggleMenuOpen) {

        return [<Menu.Item key="home" >
                    <Link to="/" onClick={toggleMenuOpen}>Home</Link>
                </Menu.Item>,
                <Menu.Item key="covid" >
                    <Link to="/covid" onClick={toggleMenuOpen}>Covid</Link>
                </Menu.Item>,
                <Menu.Item key="our_classes" >
                    <Link to="/classes" onClick={toggleMenuOpen}>Our Classes</Link>
                </Menu.Item>,
                <Menu.Item key="locations" >
                    <Link to="/locations" onClick={toggleMenuOpen}>Locations</Link>
                </Menu.Item>,
                <Menu.Item key="instructors" >
                    <Link to="/instructors" onClick={toggleMenuOpen}>Instructors</Link>
                </Menu.Item>,
                <Menu.SubMenu title='Syllabus'>
                    <Menu.Item key="adult-syllabus" >
                        <Link to="/adults" onClick={toggleMenuOpen}>Adults</Link>
                    </Menu.Item>
                    <Menu.Item key="family-syllabus" >
                        <Link to="/juniors" onClick={toggleMenuOpen}>Juniors and Families</Link>
                    </Menu.Item>
                </Menu.SubMenu>
                ];
    }

    static getDerivedStateFromProps(props, state) {

        props.sessionStore.setIsMobileMenu(props.windowWidth);

        return state;
    }

    render() {

        let { mobileMenuOpen, toggleMobileMenuOpen, isMobileMenu } = this.props.sessionStore;
        const BrandHeader = <div className="navbar-brand-text"><img className='navbar-logo' src={MyImage} alt='Ippon Ryu Ju Jitsu'/>Ippon Ryu Ju Jitsu</div>;

        const innerToggleMobileMenuOpen = isMobileMenu ? () => {toggleMobileMenuOpen()} : null;

        return <>
            <Drawer className='navigation-drawer' title={BrandHeader}
                placement='left'
                closable={false}
                onClose={innerToggleMobileMenuOpen}
                visible={mobileMenuOpen}
                style={{
                    fontSize:'xxx-large'
                }}>
                <Menu style={{lineHeight: '64px'}} mode="inline">
                    {this.renderNavLinks(innerToggleMobileMenuOpen)}
                </Menu>
            </Drawer>

            <Layout.Header style={{background:'white'}}>
                {BrandHeader}
                <Menu mode="horizontal" style={{ float:'right',
                        lineHeight: '64px' }}>
                    { isMobileMenu ? <Menu.Item><Button className='navigation-bars-button' icon="bars" onClick={innerToggleMobileMenuOpen}/></Menu.Item> : this.renderNavLinks()}
                </Menu>
            </Layout.Header>
        </>;
    }
}


export default windowSize(Navigation);
