import React from 'react';
import { Row, Col} from 'antd';
import ClassImage from './overview_strike.png';

const OurClasses = (props) => {
  return <div>
            <h1 className='ippon-ryu-font'>Our Classes</h1>
            <hr/>
            <h3>Class Overview</h3>
            <Row  type="flex" justify="center">
                <Col md={12}>
                    <p>Most of our classes broadly follow the same format:</p>
                    <ul>
                        <li>A class will normally being with a line up and Rei (bow) to each other that signifies the start of training.</li>
                        <li>We then warm ourselves up with a series of exercises and stretches for around 10-15 minutes to reduce the likelihood of muscular injury during the training session.</li>
                        <li>The whole class then runs through a series of Ukemi (Breakfalls) which are about learning to control your own landing during the techniques</li>
                        <li>We teach formalised striking through a line-up, and this includes punching, kicking and combination techniques.</li>
                        <li>The whole class will then run through an area or family of Ju Jitsu techniques such as a particular series of throws or joint locking techniques</li>
                        <li>At the mid-way point students will break off into Grade groups to focus on the techniques for the grade they are working towards</li>
                        <li>For the last 10-15 minutes we will normally have a small grappling or sparring session</li>
                        <li>We close with a warm-down and final Rei to complete training</li>
                    </ul>
                </Col>
                <Col md={12}>
                    <img src={ClassImage} alt='Example techniques'/>
                </Col>
            </Row>
        </div>
};

export default OurClasses;