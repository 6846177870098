import React from 'react';
import {Row, Col} from 'antd';

import Andrew from './andrew.jpg';
import Chris from './chris.jpg';

const Instructors = () => (<div>
    <h1 className='ippon-ryu-font'>Our Instructors</h1>
    <hr/>
    <p>All our Instructors are DBS checked, First Aid trained, and registered with the <a href="http://www.bjjagb.com">British Ju Jitsu
        Association Governing Body (BJJAGB)</a>.</p>

    <Row>
        <h2 className='ippon-ryu-font'>Sensei Andrew Newman, 3rd Dan</h2>
    </Row>
    <Row>
        <p>Sensei Andrew has been teaching Ju Jitsu since 2012 and is one of the 2 founder Instructors of Ippon Ryu Ju Jitsu.
            Andrew is Chief Instructor of the Ippon Ryu Ju Jitsu Association.</p>
    </Row>
    <Row type='flex' justify='start'>
        <Col><img style={{marginRight:'20px', width: '200px'}} src={Andrew} alt='Andrew Newman'/></Col>
        <Col><div style={{maxWidth:'600px'}}><p>I started my martial arts career in 1985 when I took up Wado Ryu karate and started
                                teaching karate around 1990. In 2008 I met Sensei John Andrews who talked me into joining
                                his Tenki Ryu jujitsu class. It fitted completely with my karate training and covered a
                                number of additional areas that were new to me. I love to learn new things and so I was
                                hooked. Through diligent training and my good basis in karate, I was able to achieve my
                                1st Dan in 2012.</p>

                            <p>Just after this Sensei Andrews decided to change his approach and moved
                                away from the traditional Japanese jujitsu. I decided that direction did not fit with my
                                continued karate training and so left to find another club. After much searching and not
                                finding what I was looking for, I joined with Sensei Chris Rawlings to form Ippon Ryu
                                Jujitsu in 2012. We joined the British Jujitsu Association Governing Body (BJJAGB) and
                                since then I have achieved my 3rd Dan and also hold a Level 3 coaching qualification with
                                them. I also attend regular seminars with other BJJAGB clubs and Sensei.</p>

                            <p>I still practice karate in addition to Ju Jitsu and hold the rank of 5th Dan in Washinki
                                Karate. I also practice Kendo and hold the rank of 1st Dan and am also a qualified Level
                                1 Kendo coach.</p>

                            <p>I love teaching and my training across three martial arts gives me great experience,
                                although there is always lots still to learn.</p></div>
        </Col>
        </Row>
        <Row>
            <h2 className='ippon-ryu-font'>Sensei Chris Rawlings, 3rd Dan</h2>
        </Row>
        <Row>
             <p>Sensei Chris has been teaching Ju Jitsu since 2012 and is one of the 2 founder Instructors of Ippon Ryu Ju Jitsu.</p>
        </Row>
        <Row type='flex' justify='start'>
            <Col><img style={{marginRight:'20px', width: '200px'}} src={Chris} alt='Chris Rawlings'/></Col>
            <Col><div style={{maxWidth:'600px'}}><p>I originally started learning Ju Jitsu while in Nottingham in 1992 having already tried
            Judo, Shotokan Karate and Tae Kwon Do. I had got bored of the marching-up-and-down-the-hall approach of Karate
            and TKD. My very first exposure to Ju Jitsu was through 'The Jitsu Foundation'. What originally attracted me
            was the use of Human body mechanics to make techniques work - having watched the Seagal Martial Arts films of the 90s
            I wanted to know how to make someone fly through the air using a wrist lock or throw which seemed magical to me. I trained
            there for 3 years before having a long break from martial arts as I still felt there was something missing from the TJF style of tuition.
            In my early 30s I revisited Ju Jitsu which I sorely missed and trained with 2 different styles at 2 different
            clubs simultaneously. I achieved Dan grades with both Tenki Ryu Ju Jitsu (originally a traditional Japanese style)
                under Sensei John Andrews and Mushin Ju Jitsu (a modern Interpretation) under Sensei Steve Barker in 2012.</p>
                            <p>Sensei Andrews style particularly seemed to fill the gaps that I perceived were in TJF, and
                                when Sensei Andrews took Tenki Ryu in a more Sports Ju-Jitsu direction, it was with some sadness
                                that I left to find another more traditional club in the area. Sadly none of the other clubs in the area
                                seemed quite right.</p>
                            <p>Sensei Andrew Newman and I set up Ippon Ryu Ju Jitsu at the end of 2012 as we both wanted to carry on training in the traditional
                Japanese Style we'd both come to love. I still enjoy training and I love teaching JJ and coaching others to help them achieve their
                martial arts potential. The thing I love most about Ju Jitsu is the fact that there’s always something
                new to learn!</p></div>
            </Col>
    </Row>

</div>);

export default Instructors;