import React from 'react';
import {Row, Col} from 'antd';

const Covid = () => {
    return <div>
        <h1 className='ippon-ryu-font'>Measures in place for Covid 19</h1>
        <hr/>

        <h3>Introduction</h3>
        <p>
        The safety of our Instructors and Students is quite literally at the forefront of everything we do during normal
        training and doubly so during the time of Covid 19. We are governed by the <a href='http://www.bjjagb.com'>British Ju Jitsu Association</a> which
        is regulated by <a href='https://www.sportengland.org/'>Sport England</a> which both detail extensive measures we have to comply with to keep us all safe. Our Venues are also
            Covid-Safe venues.</p>

        <h3>Return to Training - 20th May 2021</h3>
        <p>
        As of 20th May 2021 we have returned to training at our Toynbee venue on Thursdays and we will assess later in the year when the best time is to restart the Tuesday Knightwood Leisure Centre venue.
        We have to adapt to the current situation and put measures in place to manage the risk posed to Students and Instructors. We are strictly following the return-to-training guidance issued by the BJJAGB <a href='http://www.bjjagb.com/news/bjjagb-return-to-play-action-plan-now-updated/'>Here</a></p>

    </div>;
};

export default Covid;
