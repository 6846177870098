import { observable, action } from 'mobx';
import {MOBILE_SCREEN_TRIGGER_WIDTH} from '../common/constants';

export default class SessionStore {

    @observable mobileMenuOpen = false;
    @observable isMobileMenu = false;
    @observable tab = 0;

    @action
    setIsMobileMenu = windowWidth => {
        this.isMobileMenu = windowWidth <= MOBILE_SCREEN_TRIGGER_WIDTH;
        //breakpoint in antd grid
    };

    @action
    toggleMobileMenuOpen = () => {
        if(!this.mobileMenuOpen) {
            if(this.isMobileMenu) {
                this.mobileMenuOpen = true;
            }
        } else {
            this.mobileMenuOpen = false;
        }
    };
}