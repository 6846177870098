import './syllabus.css';

import React from 'react';
import {Row, Col} from 'antd';
import {Link} from 'react-router-dom';

import RedBelt from './belts/red-stripe-belt.png';
import YellowBelt from './belts/yellow-stripe-belt.png';
import OrangeBelt from './belts/orange-stripe-belt.png';
import GreenBelt from './belts/green-stripe-belt.png';
import BlueBelt from './belts/blue-stripe-belt.png';
import PurpleBelt from './belts/purple-stripe-belt.png';
import BrownBelt from './belts/brown-1-stripe-belt.png';
import BrownStripeBelt from './belts/brown-2-stripe-belt.png';

const FamilySyllabus = () => {
return <div>
    <h1 className='ippon-ryu-font'>Junior and Family Syllabus</h1>
    <hr/>
    To get and idea of training and start-up costs for Juniors and Families
            go <Link to="/junior_costs">here</Link>
    <h2 className='ippon-ryu-font'>Content</h2>
    <p>We provide the Ippon Ryu Ju Jitsu Family syllabus for Juniors or Families with children that wish to train
                together. Juniors are usually taught separately from the adults unless they are training with their own
                family members. The Family Syllabus has been modified from that of the Adult Syllabus to suit the juniors'
                level of maturity as some of the Adult techniques are considered to be too dangerous for Juniors. That
                said, the traditional proven techniques of Ju Jitsu remain at the very core of the Family Syllabus and
                instruction is individually tailored to the age of the Student.</p>

            <p>Ju Jitsu is very much a ‘hands-on’ martial art and Students train in groups, normally with other Students
                of the same Grade (or close to it).</p>

            <p>Ippon Ryu provides a comprehensive Ju Jitsu syllabus that will take students of all abilities and teach
                you skills in:</p>

            <ul>
                <li>Self-defence,</li>
                <li>Joint locking such as Wrist Locks, Arm-locks and Leg-locks,</li>
                <li>Blocking, countering and throwing,</li>
                <li>Ground grappling and restraints,</li>
                <li>Striking methods such as different types of punching methods and kicks</li>
            </ul>

    <h2 className='ippon-ryu-font'>Making Progress</h2>

    <p>Progression in Ju Jitsu is broken up into stages called Kyu Grades. Reaching each new Grade is symbolised by
            the wearing a coloured belt corresponding to that grade. Depending on the age of the Student, these grades
            are further split into mini sub-grades with a ‘Tag’ called a Mon. To reach Black Belt, each student has to
            proceed through all of the Kyu grades one by one. It is at the Instructor’s discretion whether a Student
            should be graded, and they will make this decision based on a number of factors such as attendance record
            and commitment as well as ability. Everyone starts with a white Belt. It’s important to remember that
                once-upon-a-time the Instructors all started as white belts!</p>

            <p>To achieve the next grade (and so next colour belt), the Student demonstrates that they can competently perform the
                techniques when asked to do so at a test called a ‘Grading’ - usually carried out in the lesson. Gradings
                are not meant to be intimidating so the Juniors have a revision session before demonstrating their
                techniques to the examining Sensei. The Senseis know the Students are nervous and take this into account!</p>

    <Row type='flex' justify='center' className='belt-image'>
        <Col><img src={RedBelt} alt='red belt'/></Col>
        <Col><img src={YellowBelt} alt='yellow belt'/></Col>
        <Col><img src={OrangeBelt} alt='orange belt'/></Col>
        <Col><img src={GreenBelt} alt='green belt'/></Col>
        <Col><img src={BlueBelt} alt='blue belt'/></Col>
        <Col><img src={PurpleBelt} alt='purple belt'/></Col>
        <Col><img src={BrownBelt} alt='brown belt'/></Col>
        <Col><img src={BrownStripeBelt} alt='brown stripe belt'/></Col>
    </Row>

    <h2 className='ippon-ryu-font'>How long until they get a Black Belt?</h2>
            <p>The Juniors tend to take a bit longer than the Seniors to learn the required techniques between grades,
            and can take 6-7 years, quite possibly longer to achieve a Black Belt. The only ‘minimum time’ between grades
                is between 1st Kyu (Brown with 2 white stripes) which is a 1 year period. It can take around 4-5 months
                to achieve a new coloured belt depending on how often the Student trains, and this is very much a partnership
                between the Student and the Sensei. The Tag system breaks this longer 4-5 month period up into chunks which
                continues to engage the Juniors in their learning. Junior black belts must be at least 12
                years old. Black Belt gradings are by invitation only when the Instructors believe they are ready.</p>

            <h2 className='ippon-ryu-font'>After Black Belt</h2>
            <p>The first Black Belt you get is called Shodan or ‘First Dan’. Note the word ‘First’! Really a Black Belt
            means that you aren’t a beginner anymore. In the world of Ju Jitsu there is so much more to learn. The club
            has additional Family Syllabus content for the next levels of Black Belt (‘Dan’ means level in Japanese)
            for 2nd and 3rd Dans. By this point the Junior will realistically be an Adult so will continue onto the
                Adult Syllabus.</p>
</div>
};

export default FamilySyllabus;