import './costs.css';

import React from 'react';
import {Row, Col} from 'antd';


const FamilyCosts = () => {
return <div>
    <h1 className='ippon-ryu-font'>Costs for Children &amp; Families</h1>
    <hr/>
    Training and costs for Juniors and Families are shown below. To get a training discount where multiple students
    come from the same family, please speak to an Instructor. We cannot unfortunately discount Licence, Grading or
    equipment costs. Family costs apply to both Adults and Children in the Family session.

    <h2 className='ippon-ryu-font'>Start-up costs</h2>
    Once a student has come for a few weeks and wants to continue training then we ask that you purchase a
    Judo suit (called a Gi) and a BJJAGB licence. You have to get the licence through us, and you can opt to get a
    suit through us or purchase this yourself - however we insist that this is plain white with no markings.
    <ul>
        <li>Licence is £20 paid annually. If you do not purchase a Licence then we're afraid you
            cannot carry on training.</li>
        <li>Suit is between £18 to £30 depending on the height and build of the Student. These only need replacing
            when they are worn out or grown out of.</li>
    </ul>

    <h2 className='ippon-ryu-font'>Weekly training costs</h2>
    <ul>
        <li>If 2 or more child students come from the same family then speak to an Instructor to arrange a reduction on these fees</li>
        <li>The standard pay-on-the-day cost for a Children and Family training session is £5.00</li>
        <li>Pay Monthly for once-a-week training at any location is £15 paid at the beginning of the month</li>
        <li>Pay Monthly for twice-a-week training at any location is £25 paid at the beginning of the month</li>
        <li>For 3 times a week discuss options with an Instructor</li>
    </ul>

    <h2 className='ippon-ryu-font'>Grading Costs</h2>
    <ul>
        <li>There is no charge for a tag grading</li>
        <li>There is a £5 Grading charge when taking a Belt Grading</li>
    </ul>  
</div>
};

export default FamilyCosts;